<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('admin_dashboard_customers_title') }}</dmx-title>

        <b-row align-v="center" class="mb-1">
            <b-col>
                {{ pagination.totalListCount }} customers found
                <span class="ml-3">
                    Show: <b-link @click="setPerPage(10)" :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                    <b-link @click="setPerPage(20)" :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                    <b-link @click="setPerPage(1000)" :style="((options.perPage == 1000) ? 'font-weight: bold;' : ' ')">All</b-link>
                </span>
            </b-col>
            <b-col>
                <b-input v-model="searchField"
                         @input="searchWithText()"
                         :loading="searchFieldIsLoading"
                         :placeholder="$t('common_search')">
                </b-input>
            </b-col>
            <b-col align="right">
                <b-button variant="success" @click="newCustomer()">
                    <b-icon icon="plus"></b-icon>
                    {{ $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>
        <b-table id="customers-table"
                 thead-class="dmx-thead-block"
                 :busy="loading"
                 outlined
                 striped
                 bordered
                 hover
                 :items="customerList"
                 :fields="headers"
                 @row-clicked="editCustomer">
            <template #cell(updatedOnUtc)="{ value }">
                {{ updatedOnFormatted(value) }}
            </template>
        </b-table>

        <div>
            <b-pagination align="center" v-model="options.currentPage"
                          :total-rows="pagination.totalListCount"
                          :per-page="options.perPage"
                          aria-controls="devices-table">
            </b-pagination>
        </div>


 
    </div>
</template>

<style scoped>

    /* gives table headers rounder corners */
    /*/deep/ tr th:first-child {
        border-top-left-radius: 10px;
    }*/

    /* gives table headers rounder corners */
    /*/deep/ tr th:last-child {
        border-top-right-radius: 10px;
    }*/

</style>

<script>
    import customerService from '@/services/customerService';
    import router from '@/router';
    import moment from 'moment';

    export default {
        name: "customer-list",
        data: () => ({
            search: '',
            loading: true,
            options: {
                perPage: 10,
                currentPage: 1,
            },

            //flags
            searchFieldIsLoading: false,

            // values
            searchField: '',
            customerList: [],

            // pagination
            pagination: {
                totalListCount: 0
            },
            filterTableHeadName: '',
            timeoutId: 0,
            bcItems: [
                {
                    text: 'customers',
                    href: '/customer/customerList',
                    active: true,
                }
            ]
        }),
        computed: {
            headers() {
                return [
                    {
                        label: this.$t('common_name'),
                        sortable: true,
                        key: 'name',
                    },
                    {
                        label: this.$t('common_email'),
                        sortable: true,
                        key: 'email'
                    },
                    {
                        label: this.$t('common_number'),
                        sortable: true,
                        key: 'number',
                    },
                    {
                        label: this.$t('common_organisation_number'),
                        sortable: true,
                        key: 'organisationNumber',
                    },
                    {
                        label: this.$t('common_creatededited'),
                        sortable: true,
                        key: 'updatedOnUtc'
                    },
                ]
            }
        },
        watch: {
            options: {
                handler() {
                    this.getCustomers()
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {
            updatedOnFormatted(date) {
                return moment(date).format('YYYY-MM-DD HH:mm:ss');
            },
            setPerPage(num) {
                this.options.currentPage = 1;
                this.options.perPage = num;
            },
            newCustomer() {
                router.push({ name: 'customerEdit', params: { customerId: 0 } });
            },
            editCustomer(e) {
                router.push({ name: 'customerEdit', params: { customerId: parseInt(e.id) } });
            },
            searchWithText() {
                this.searchFieldIsLoading = true;

                if (this.timeoutId > 0) {
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = window.setTimeout(() => {
                    this.getCustomers();
                }, 600);
            },

            filterByTableHeader(tableHeaderName) {
                this.filterTableHeadName = tableHeaderName;
                this.getCustomers();
            },
            getCustomers() {
                customerService.getCustomerList({
                    Count: this.options.perPage,
                    // SortBy: this.options.sortBy[0],
                    SearchTerm: this.searchField,
                    // SortDesc: this.options.sortDesc[0],
                    Page: this.options.currentPage
                })
                    .then((data) => {
                        this.customerList = data.items;
                        this.searchFieldIsLoading = false;
                        this.options.currentPage = data.page;
                        this.pagination.totalListCount = data.totalListCount;

                        this.loading = false;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            formatDate(value) {
                return new Date(value).toLocaleString()
            }
        }
    }
</script>
